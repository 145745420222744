<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary"
      @click.prevent="setSelected"
      data-toggle="modal"
      data-target="#update-toilettage-activity"
    ><i class="icofont icofont-pencil" /></a>
    <a
      href="javascript:void(0)"
      class="btn btn-third"
      @click.prevent="deleting"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import { DELETE_TOILETTAGE_ACTIVITY } from '../../../../graphql/sanitaire';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return{
  
          }
      },
      methods: {
          ...mapMutations({
              setSelectedObject: 'SET_SELECTED_OBJECT',
          }),
          setSelected(){
              this.setSelectedObject(this.object)
          },
          deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_TOILETTAGE_ACTIVITY,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Toilettage activité ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting toilettage activité ${this.object.libelle}`)
                  }
                }).then(() => {
                  
                })
          }
      },
      computed: {
          ...mapGetters({
  
          })
      }
  }
  </script>
  
  <style>
  
  </style>